<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">{{ translations.tcEditGideoncardFacility }}</h1>
    </page-header>

    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-4">{{ translations.tcEditGideoncardFacility }}</h1>
        <div class="body">
          <b-form>
            <div class="row">
              <b-col sm="6">
                <b-row>
                  <b-col sm="12" class="font-style-3">
                    <b-form-group
                      id="select-group-1"
                      :label="`${translations.tcName}`"
                      label-for="location_image"
                      label-class="form-group-label"
                      class="form-element"
                    >
                      <div class="mb-3">
                        <b-form-input
                          id="facility_name"
                          v-model="profileSummary.org_name"
                          class="flex-0"
                          type="text"
                        ></b-form-input>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  id="select-group-1"
                  :label="`${translations.tcImageLocation}`"
                  label-for="location_image"
                  label-class="form-group-label"
                  class="form-element"
                >
                  <b-form-file
                    id="location_image"
                    style="text-transform: uppercase"
                    ref="fileUploadImg"
                    v-model.lazy="location_image_file"
                    name="location_image"
                    :placeholder="`${translations.tcImageLocation}`"
                    :drop-placeholder="`${translations.tcDropFileHere}...`"
                    :browse-text="`${translations.tcBrowse}`"
                  />
                </b-form-group>
                <div style="margin-top: 30px">
                  <img
                    v-if="!!profileSummary.image_url"
                    :src="profileSummary.image_url"
                    class="church_image"
                    height="185"
                    width="285"
                    :alt="`${translations.tcImageLocation}`"
                  />
                  <camera v-if="!profileSummary.image_url"></camera>
                </div>
              </b-col>
            </div>
          </b-form>
        </div>
      </section>

      <section class="section-3b bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcDetails }}</h2>
        </header>
        <div class="body">
          <b-form>
            <div class="row">
              <div class="col-8">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <label class="font-style-1">{{ translations.tcCampAssignedNumber }}</label>
                  </div>
                  <div class="mb-3">
                    <b-form-input
                      id="campassignednumber"
                      v-model="profileSummary.camp_assigned_number"
                      class="flex-0"
                      type="text"
                    ></b-form-input>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <label class="font-style-1">{{ translations.tcWebsite }}</label>
                  </div>
                  <div class="mb-3">
                    <b-form-input
                      id="facility_url"
                      v-model="profileSummary.url"
                      class="flex-0"
                      type="text"
                    ></b-form-input>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <b-form-group class="form-element">
                  <div class="date-select-1 mb-1">
                    <label class="font-style-1">{{ translations.tcGideonCardDisplays }}</label>
                  </div>
                  <div class="mb-3">
                    <b-form-input
                      id="display_number"
                      v-model="profileSummary.gideoncard_display_number"
                      class="flex-0"
                      min="0"
                      type="number"
                    ></b-form-input>
                  </div>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </div>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <textarea name="other" v-model="profileSummary.note" class="w-100"></textarea>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcSave
            }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import camera from '@/assets/svgs/camera.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'card_facility_edit_facility',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      statusKey: null,
      facility: {
        org_key: '',
        org_name: '',
        org_url: '',
        org_note: '',
        ind_key: '',
        org_camp_assigned_number: '',
        gcf_where_we_should_be_flag: false,
        gcf_number_of_gideoncard_racks: '',
        image_url: '',
      },
      location_image_file: null,
    }
  },
  methods: {
    ...mapActions({
      loadProfile: 'card/getProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
      editFacility: 'card/editFacility',
      savePhotoLocation: 'scriptureDistribution/savePhoto',
      savePhotoChurch: 'churchMinistry/savePhoto',
    }),
    async pageLoad() {
      const result = this.loadProfile(this.userSelectedFacilityKey).then(() => {})
      await result
    },
    handleSaveClick() {
      if (this.profileSummary.org_name === '') {
        this.$swal({
          icon: 'error',
          confirmButtonText: this.translations.tcOk,
          text: this.translations.tcFacilityNameRequired,
        })
        return
      } else {
        this.facility.org_key = this.userSelectedFacilityKey
        this.facility.ind_key = this.userId
        this.facility.org_name = this.profileSummary.org_name
        this.facility.org_url = this.profileSummary.url
        this.facility.org_note = this.profileSummary.note
        this.facility.org_camp_assigned_number = this.profileSummary.camp_assigned_number
        this.facility.gcf_where_we_should_be_flag = false
        this.facility.gcf_number_of_gideoncard_racks = this.profileSummary.gideoncard_display_number
        this.submitRequest(this.facility)
      }
    },
    handleCancelClick() {
      this.$router.push('/programs/gc/profile/card_details')
    },
    async submitRequest(data) {
      var payload = {
        iim_org_key: this.facility.org_key,
        iim_image: null,
        iim_user: this.facility.ind_key,
        iim_delete_flag: false,
      }
      let response = null
      if (this.location_image_file !== '') {
        await (payload.iim_image = this.location_image_file)
        if (this.profileSummary.org_type_name.toLowerCase() == 'church') {
          response = await this.savePhotoChurch(payload)
        } else {
          response = await this.savePhotoLocation(payload)
        }
      }
      await Promise.all([this.setLoadingStatus(true), await this.editFacility(data)]).then((result) => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: result[1] ? 'success' : 'error',
          confirmButtonText: this.translations.tcOk,
          text: result[1]
            ? `${this.translations.tcSaveFacilitySuccessful}.`
            : `${this.translations.tcSaveFacilityFailure}.`,
        }).then((result) => {
          if (result.value) {
            this.$router.push('/programs/gc/profile/card_details')
            return
          }
        })
      })
    },
  },
  async created() {
    this.setLoadingStatus(true)
    Promise.all([this.getViewTranslations('buttons'), this.pageLoad()]).finally(() => {
      this.setLoadingStatus(false)
    })
    this.setLoadingStatus(false)
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      profileSummary: 'card/getFacilitySummary',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
    }),
  },
  components: {
    pageHeader: pageHeader,
    pageBody: pageBody,
    camera: camera,
    pageFooter: pageFooter,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
